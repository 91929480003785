import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'; // Si deseas agregar estilos personalizados

function Dashboard() {
    const navigate = useNavigate();

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="row text-center">
                <div className="col-12 mb-4">
                    <h1>Bienvenido</h1>
                    <h2>Escoja una opción</h2>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <button 
                        className="btn btn-primary btn-lg w-100"
                        onClick={() => navigate('/ver-registros')}
                    >
                        Ver Registros
                    </button>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <button 
                        className="btn btn-success btn-lg w-100"
                        onClick={() => navigate('/registro-avance')}
                    >
                        Registrar Predicación
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
