import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const api = axios.create({
    baseURL: 'https://registrosurtemuco.cl/api/',
});

api.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    if (token && new Date().getTime() / 1000 > tokenExpiration) {
        try {
            const response = await axios.post('https://registrosurtemuco.cl/api/token/refresh/', {
                refresh: refreshToken,
            });

            token = response.data.access;
            const decodedToken = jwtDecode(token);
            const expirationTime = decodedToken.exp;

            localStorage.setItem('token', token);
            localStorage.setItem('tokenExpiration', expirationTime);
        } catch (error) {
            // Si el refresco falla, redirigir al login y limpiar localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenExpiration');
            window.location.href = '/';
            return Promise.reject(error);
        }
    }

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;
