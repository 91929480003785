import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import api from '../../api';
import './Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
  
          if (decodedToken.exp > currentTime) {
            console.log("pasó por acá decodedToken");
            navigate('/dashboard');
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    }, [navigate]);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const response = await api.post('/token/', { username, password });
        const { access, refresh } = response.data;
  
        const decodedToken = jwtDecode(access);
        const expirationTime = decodedToken.exp;
  
        localStorage.setItem('token', access);
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('tokenExpiration', expirationTime);
        console.log("pasó por acá localstorage");
        navigate('/dashboard');
      } catch (err) {
        setError('Usuario o contraseña incorrectos');
      }
    };
  
    return (
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Iniciar Sesión</h2>
          {error && <p className="text-danger">{error}</p>}
          <div className="form-group">
            <label htmlFor="username">Usuario</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Ingresar</button>
        </form>
      </div>
    );
  }
  
  export default Login;