import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/NavBar/Navbar';
import Login from './components/Login/Login';
import RegistroAvanceForm from './components/RegistroAvance/RegistroAvanceForm';
import Dashboard from './components/Dashboard/Dashboard';
import RevisarRegistros from './components/RevisarRegistros/RevisarRegistros';
import RegistroManzanaForm from './components/RegistroManzanas/RegistroManzanaForm';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
}

function App() {
    return (
        <Router>
            <Routes>
                {/* Rutas públicas */}
                <Route path="/login" element={<Login />} />

                {/* Rutas privadas */}
                <Route path="*" element={
                    <PrivateRoute>
                        <>
                            <Navbar />
                            <Routes>
                                <Route path="/registro-avance" element={<RegistroAvanceForm />} />
                                <Route path="/ver-registros" element={<RevisarRegistros />} />
                                <Route path="/manzanas" element={<RegistroManzanaForm />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                            </Routes>
                        </>
                    </PrivateRoute>
                } />
            </Routes>
        </Router>
    );
}

export default App;
