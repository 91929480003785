import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Alert, Form, Button, Row, Col } from 'react-bootstrap';
import './RegistroManzanaForm.css';

function RegistroManzanaForm() {
    const [territorios, setTerritorios] = useState([]);
    const [selectedTerritorio, setSelectedTerritorio] = useState('');
    const [manzanaInicio, setManzanaInicio] = useState('');
    const [manzanaFin, setManzanaFin] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        // Obtener territorios desde la API
        api.get('/territorios/')
            .then(response => setTerritorios(response.data))
            .catch(error => console.error('Error al obtener los territorios:', error));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
    
        // Validaciones básicas
        if (!selectedTerritorio || !manzanaInicio || !manzanaFin) {
            setError('Todos los campos son obligatorios.');
            return;
        }
    
        if (isNaN(manzanaInicio) || isNaN(manzanaFin)) {
            setError('Los valores de las manzanas deben ser números.');
            return;
        }
    
        const inicio = parseInt(manzanaInicio);
        const fin = parseInt(manzanaFin);
    
        if (inicio > fin) {
            setError('El número de inicio no puede ser mayor que el número de fin.');
            return;
        }
    
        // Crear manzanas desde el número de inicio hasta el número de fin
        try {
            for (let i = inicio; i <= fin; i++) {
                await api.post('/manzanas/', {
                    numero: i,
                    territorio: parseInt(selectedTerritorio), // Asegurarse de que el territorio se envía correctamente
                });
            }
            setSuccess('Las manzanas se han registrado exitosamente.');
            // Resetea el formulario
            setSelectedTerritorio('');
            setManzanaInicio('');
            setManzanaFin('');
        } catch (err) {
            console.error('Error al registrar las manzanas:', err.response ? err.response.data : err);
            setError('Hubo un problema al registrar las manzanas.');
        }
    };
    

    return (
        <div className="registro-avance-form-container">
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            <Form onSubmit={handleSubmit}>
                <h2>Registrar Manzanas</h2>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="territorioSelect">
                            <Form.Label>Territorio</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedTerritorio}
                                onChange={(e) => setSelectedTerritorio(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un territorio</option>
                                {territorios.map(territorio => (
                                    <option key={territorio.id} value={territorio.id}>
                                        Territorio {territorio.numero}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Group controlId="manzanaInicio">
                            <Form.Label>Número de Manzana (Inicio)</Form.Label>
                            <Form.Control
                                type="number"
                                value={manzanaInicio}
                                onChange={(e) => setManzanaInicio(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Group controlId="manzanaFin">
                            <Form.Label>Número de Manzana (Fin)</Form.Label>
                            <Form.Control
                                type="number"
                                value={manzanaFin}
                                onChange={(e) => setManzanaFin(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" variant="primary" className="mt-3">Registrar</Button>
            </Form>
        </div>
    );
}

export default RegistroManzanaForm;
