import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Alert } from 'react-bootstrap';
import './RegistroAvanceForm.css';

function RegistroAvanceForm() {
    const [grupos, setGrupos] = useState([]);
    const [territorios, setTerritorios] = useState([]);
    const [manzanas, setManzanas] = useState([]);
    const [selectedGrupo, setSelectedGrupo] = useState('');
    const [selectedTerritorio, setSelectedTerritorio] = useState('');
    const [imagenTerritorio, setImagenTerritorio] = useState('');
    const [fecha, setFecha] = useState('');
    const [conductor, setConductor] = useState('');
    const [manzanasSeleccionadas, setManzanasSeleccionadas] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Control de envío

    useEffect(() => {
        api.get('/grupos/')
            .then(response => setGrupos(response.data))
            .catch(error => console.error('Error al obtener los grupos:', error));
    }, []);

    useEffect(() => {
        if (selectedGrupo) {
            api.get(`/territorios/?grupo=${selectedGrupo}`)
                .then(response => setTerritorios(response.data))
                .catch(error => console.error('Error al obtener los territorios:', error));
        }
    }, [selectedGrupo]);

    useEffect(() => {
        if (selectedTerritorio) {
            const territorio = territorios.find(t => t.id === parseInt(selectedTerritorio));
            setImagenTerritorio(territorio ? territorio.imagen_mapa : '');

            api.get(`/manzanas/?territorio=${selectedTerritorio}`)
                .then(response => setManzanas(response.data))
                .catch(error => console.error('Error al obtener las manzanas:', error));
        }
    }, [selectedTerritorio]);

    const handleManzanaChange = (manzanaId) => {
        setManzanasSeleccionadas(prev => 
            prev.includes(manzanaId) ? prev.filter(id => id !== manzanaId) : [...prev, manzanaId]
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        // Prevenir múltiple envíos
        if (isSubmitting) return;
        
        setIsSubmitting(true); // Deshabilitar botón
        if (manzanasSeleccionadas.length === 0) {
            setError('Debe seleccionar al menos una manzana.');
            setIsSubmitting(false); // Rehabilitar botón
            return;
        }

        try {
            for (const manzanaId of manzanasSeleccionadas) {
                if (!manzanaId) {
                    setError('El campo manzana es obligatorio.');
                    setIsSubmitting(false); // Rehabilitar botón
                    return;
                }

                // Enviar los datos incluyendo grupo, territorio, manzana, conductor y fecha
                await api.post('/registros/', {
                    grupo: parseInt(selectedGrupo),
                    territorio: parseInt(selectedTerritorio),
                    manzana: manzanaId,
                    conductor,
                    fecha,
                });
            }

            setSuccess('El registro se ha guardado exitosamente.');
            // Resetea el formulario
            setSelectedGrupo('');
            setSelectedTerritorio('');
            setImagenTerritorio('');
            setFecha('');
            setConductor('');
            setManzanasSeleccionadas([]);
            setManzanas([]); // Restablece la lista de manzanas
        } catch (err) {
            console.error('Error al enviar los datos:', err.response ? err.response.data : err);
            setError('Hubo un problema al enviar el formulario.');
        } finally {
            setIsSubmitting(false); // Rehabilitar botón
        }
    };

    return (
        <div className="registro-avance-form-container">
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            <form onSubmit={handleSubmit} className="registro-avance-form">
                <h2>Registrar Avance</h2>
                <div className="form-group">
                    <label htmlFor="grupo">Grupo</label>
                    <select
                        className="form-control"
                        id="grupo"
                        value={selectedGrupo}
                        onChange={(e) => setSelectedGrupo(e.target.value)}
                        required
                    >
                        <option value="">Seleccione un grupo</option>
                        {grupos.map(grupo => (
                            <option key={grupo.id} value={grupo.id}>{grupo.nombre}</option>
                        ))}
                    </select>
                </div>
                {selectedGrupo && (
                    <div className="form-group">
                        <label htmlFor="territorio">Territorio</label>
                        <select
                            className="form-control"
                            id="territorio"
                            value={selectedTerritorio}
                            onChange={(e) => setSelectedTerritorio(e.target.value)}
                            required
                        >
                            <option value="">Seleccione un territorio</option>
                            {territorios.map(territorio => (
                                <option key={territorio.id} value={territorio.id}>
                                    Territorio {territorio.numero}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {imagenTerritorio && (
                    <div className="form-group">
                        <label>Mapa del Territorio</label>
                        <img src={imagenTerritorio} alt="Mapa del Territorio" className="img-fluid" />
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="conductor">Conductor</label>
                    <input
                        type="text"
                        className="form-control"
                        id="conductor"
                        value={conductor}
                        onChange={(e) => setConductor(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="fecha">Fecha</label>
                    <input
                        type="date"
                        className="form-control"
                        id="fecha"
                        value={fecha}
                        onChange={(e) => setFecha(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Manzanas</label>
                    {manzanas.map(manzana => (
                        <div key={manzana.id} className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={manzana.id}
                                id={`manzana-${manzana.id}`}
                                onChange={() => handleManzanaChange(manzana.id)}
                            />
                            <label className="form-check-label" htmlFor={`manzana-${manzana.id}`}>
                                Manzana {manzana.numero}
                            </label>
                        </div>
                    ))}
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? 'Registrando...' : 'Registrar'}
                </button>
            </form>
        </div>
    );
}

export default RegistroAvanceForm;
