import React, { useEffect, useState } from 'react';
import api from '../../api';
import { Table, Pagination, Form, Button, Row, Col } from 'react-bootstrap';
import './RevisarRegistros.css';

function RevisarRegistros() {
    const [registros, setRegistros] = useState([]);
    const [filteredRegistros, setFilteredRegistros] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [territorios, setTerritorios] = useState([]);
    const [selectedGrupo, setSelectedGrupo] = useState('');
    const [selectedTerritorio, setSelectedTerritorio] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'fecha', direction: 'desc' });
    const [currentPage, setCurrentPage] = useState(1);
    const registrosPerPage = 100;

    useEffect(() => {
        api.get('/registros/')
            .then(response => {
                console.log('Datos recibidos de la API:', response.data);
                setRegistros(response.data);
                setFilteredRegistros(response.data);
            })
            .catch(error => console.error('Error al obtener los registros:', error));
    }, []);

    useEffect(() => {
        api.get('/grupos/')
            .then(response => setGrupos(response.data))
            .catch(error => console.error('Error al obtener los grupos:', error));
    }, []);

    useEffect(() => {
        if (selectedGrupo) {
            api.get(`/territorios/?grupo=${selectedGrupo}`)
                .then(response => setTerritorios(response.data))
                .catch(error => console.error('Error al obtener los territorios:', error));
        } else {
            setTerritorios([]);  // Limpiar los territorios si no hay grupo seleccionado
        }
    }, [selectedGrupo]);

    useEffect(() => {
        filterRegistros();
    }, [selectedGrupo, selectedTerritorio, selectedYear, selectedMonth]);

    const filterRegistros = () => {
        let filtered = registros;

        // Filtro por grupo (comparar por el nombre del grupo o ID si es necesario)
        if (selectedGrupo) {
            filtered = filtered.filter(registro => registro.grupo && registro.grupo.id === parseInt(selectedGrupo));
        }

        // Filtro por territorio (comparar por el número del territorio o ID si es necesario)
        if (selectedTerritorio) {
            filtered = filtered.filter(registro => registro.territorio && registro.territorio.id === parseInt(selectedTerritorio));
        }

        // Filtro por año
        if (selectedYear) {
            filtered = filtered.filter(registro => new Date(registro.fecha).getFullYear() === parseInt(selectedYear));
        }

        // Filtro por mes
        if (selectedMonth) {
            filtered = filtered.filter(registro => new Date(registro.fecha).getMonth() + 1 === parseInt(selectedMonth));
        }

        setFilteredRegistros(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    const handleResetFilters = () => {
        setSelectedGrupo('');
        setSelectedTerritorio('');
        setSelectedYear('');
        setSelectedMonth('');
        setFilteredRegistros(registros); // Resetear registros a la lista completa
    };

    const sortedRegistros = [...filteredRegistros].sort((a, b) => {
        if (sortConfig.key === 'fecha') {
            const dateA = new Date(a.fecha);
            const dateB = new Date(b.fecha);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else {
            const valA = a[sortConfig.key];
            const valB = b[sortConfig.key];
            if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1;
            if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        }
    });

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastRegistro = currentPage * registrosPerPage;
    const indexOfFirstRegistro = indexOfLastRegistro - registrosPerPage;
    const currentRegistros = sortedRegistros.slice(indexOfFirstRegistro, indexOfLastRegistro);

    const totalPages = Math.ceil(filteredRegistros.length / registrosPerPage);

    return (
        <div className="table-container">
            <h2>Registros de Manzanas</h2>

            {/* Filtros */}
            <Form className="mb-3">
                <Row className="align-items-end">
                    <Col xs={12} sm={6} md={3}>
                        <Form.Group controlId="grupoSelect">
                            <Form.Label>Grupo</Form.Label>
                            <Form.Control 
                                size="sm" 
                                as="select" 
                                value={selectedGrupo} 
                                onChange={(e) => setSelectedGrupo(e.target.value)}>
                                <option value="">Todos los Grupos</option>
                                {grupos.map(grupo => (
                                    <option key={grupo.id} value={grupo.id}>{grupo.nombre}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Form.Group controlId="territorioSelect">
                            <Form.Label>Territorio</Form.Label>
                            <Form.Control 
                                size="sm" 
                                as="select" 
                                value={selectedTerritorio} 
                                onChange={(e) => setSelectedTerritorio(e.target.value)}
                                disabled={!selectedGrupo}>
                                <option value="">Todos los Territorios</option>
                                {territorios.map(territorio => (
                                    <option key={territorio.id} value={territorio.id}>{territorio.numero}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Form.Group controlId="yearSelect">
                            <Form.Label>Año</Form.Label>
                            <Form.Control 
                                size="sm" 
                                as="select" 
                                value={selectedYear} 
                                onChange={(e) => setSelectedYear(e.target.value)}>
                                <option value="">Todos los Años</option>
                                {[...new Set(registros.map(registro => new Date(registro.fecha).getFullYear()))].map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Form.Group controlId="monthSelect">
                            <Form.Label>Mes</Form.Label>
                            <Form.Control 
                                size="sm" 
                                as="select" 
                                value={selectedMonth} 
                                onChange={(e) => setSelectedMonth(e.target.value)}>
                                <option value="">Todos los Meses</option>
                                {[...new Set(registros.map(registro => new Date(registro.fecha).getMonth() + 1))].map(month => (
                                    <option key={month} value={month}>{month}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Button 
                            size="sm" 
                            variant="secondary" 
                            onClick={handleResetFilters} 
                            className="w-100 mt-2 mt-md-2">
                            Resetear Filtros
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th onClick={() => requestSort('grupo')}>Grupo</th>
                        <th onClick={() => requestSort('territorio')}>Territorio</th>
                        <th onClick={() => requestSort('manzana')}>Manzana</th>
                        <th onClick={() => requestSort('conductor')}>Conductor</th>
                        <th onClick={() => requestSort('fecha')}>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRegistros.map((registro) => {
                        const grupoNombre = registro.grupo ? registro.grupo.nombre : 'Sin grupo';
                        const territorioNumero = registro.territorio ? registro.territorio.numero : 'Sin territorio';
                        const manzanaNumero = registro.manzana ? registro.manzana.numero : 'Sin manzana';
                        return (
                            <tr key={registro.id}>
                                <td>{grupoNombre}</td>
                                <td>{territorioNumero}</td>
                                <td>{manzanaNumero}</td>
                                <td>{registro.conductor}</td>
                                <td>{registro.fecha.split('-').reverse().join('/')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            {/* Paginación */}
            <Pagination>
                {[...Array(totalPages).keys()].map(page => (
                    <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => paginate(page + 1)}>
                        {page + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
}

export default RevisarRegistros;
