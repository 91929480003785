import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api'; // Importa la configuración de axios
import './Navbar.css'; // Si deseas agregar estilos personalizados

function Navbar() {
    const navigate = useNavigate();

    const handleLogout = () => {
        const refreshToken = localStorage.getItem('refreshToken');
        
        if (refreshToken) {
            // Hacer una solicitud al backend para invalidar el refresh token
            api.post('/logout/', { refresh_token: refreshToken })
                .then(() => {
                    // Elimina el token de autenticación y el refresh token
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('tokenExpiration');
                    
                    // Redirige al usuario a la página de inicio de sesión
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error al cerrar sesión:', error);
                    
                    // Asegurarse de que los tokens se eliminen incluso si ocurre un error
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('tokenExpiration');
                    
                    // Redirigir al usuario a la página de inicio de sesión
                    navigate('/login');
                });
        } else {
            // Si no hay refresh token, simplemente elimina los tokens y redirige
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenExpiration');
            navigate('/login');
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/dashboard">Sistema de Registro</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard">Inicio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/registro-avance">Registrar Avance</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/ver-registros">Revisar Registros</Link>
                        </li>
                    </ul>
                    <button className="btn btn-outline-danger" onClick={handleLogout}>Cerrar Sesión</button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
